import React from "react"

import BackgroundImage from "gatsby-background-image"

export default function Breadcrumb({ breadcrumb }) {
  return (
    <BackgroundImage
      className="breadcrumb-area jarallax"
      fluid={{
        ...breadcrumb.proprieta.immagine.localFile.childImageSharp.fluid,
        sizes: "(max-width: 512px) 80vw",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner">
              <ul className="page-list">
                <li>{breadcrumb.proprieta.citta[0].title}</li>
              </ul>
              <h1 className="page-title">{breadcrumb.title}</h1>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}
