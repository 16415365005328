import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"

import Img from "gatsby-image"

import Contact from "../components/contact"
import Form from "../components/form"

import logoProposte from "../images/logo-proposte.svg"

import monolocale from "../images/monolocale.svg"
import bilocale from "../images/bilocale.svg"
import trilocale from "../images/trilocale.svg"
import quadrilocale from "../images/quadrilocale.svg"
import attico from "../images/attico.svg"
import Breadcrumb from "../components/breadcrumb"

export default function ProprietaPage({ data }) {
  let imagealt = "Sogno Casa Liguria"

  const post = data.allWpProprieta.nodes[0]
  return (
    <Layout>
      <SEO title={post.title} />

      <Breadcrumb breadcrumb={post} />

      <div className="property-details-area">
        <div className="container pd-top-100">
          <div className="property-news-single-card style-two col-xl-9 col-lg-8">
            <h4>{post.proprieta.titoloDescrizione}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: `${post.proprieta.testoDescrizione}`,
              }}
            ></p>
          </div>
        </div>
      </div>
      <div className="bg-gray-opacity pd-top-100 pd-bottom-90">
        <div className="container">

          {/* test */}
          {(function () {
            if (post.proprieta.etichetta ) {
              return (
                <span className="etichetta etichetta-proprieta etichetta-proprieta-single"
                  dangerouslySetInnerHTML={{
                    __html: `${post.proprieta.etichetta}`,
                  }}
                ></span>
              )
            } else {
              return ""
            }
          })()}

          {(function () {
            if (post.proprieta.venduta ) {
              return (
                <span className="etichetta etichetta-proprieta etichetta-proprieta-single venduta"
                  dangerouslySetInnerHTML={{
                    __html: `${post.proprieta.venduta}`,
                  }}
                ></span>
              )
            } else {
              return ""
            }
          })()}
          {/* /test */}

          <div className="row ">
            <div className="col-xl-9 col-lg-8">
              <div
                id="carouselExampleFade"
                className="carousel proprieta-carousel slide"
                data-ride="carousel"
              >
                <div className="carousel-inner apartments-slider-2">
                  {post.proprieta.slider.map((node, i) => (
                    <div
                      key={i}
                      className={`item carousel-item ${
                        i === 0 ? "active" : ""
                      }`}
                    >
                      <div className="row justify-content-center">
                        <div className="thumb">
                          <Img
                            fluid={{
                              ...node.localFile.childImageSharp.fluid,
                              sizes: "(max-width: 512px) 80vw",
                            }}
                            alt={imagealt}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {(function () {
                  if (post.proprieta.slider.length > 1) {
                    return (
                      <>
                        <a
                          className="carousel-control-prev"
                          href="#carouselExampleFade"
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          className="carousel-control-next"
                          href="#carouselExampleFade"
                          role="button"
                          data-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Next</span>
                        </a>
                      </>
                    )
                  } else {
                    return ""
                  }
                })()}
              </div>
              <div className="property-details-slider-info">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<h4>${post.proprieta.tipologia}</h4>`,
                  }}
                ></div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4">
              <div className="widget widget-owner-info mt-lg-0 mt-5">
                <div className="contact-info">
                  <h6 className="mb-3">
                    {post.proprieta.sliderContenuto.titoloSlider}
                  </h6>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${post.proprieta.sliderContenuto.testoSlider}</h4>`,
                    }}
                  ></p>
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="rld-list-style mb-3 mb-sm-0">
                        {post.proprieta.sliderContenuto.caratteristiche.map(
                          (caratteristica, i) => (
                            <li key={i} className="col-lg-12 col-12">
                              <i className="fa fa-check" /> {caratteristica}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(function () {
      if (post.proprieta.struttura ) {
        return (
      <div className="container">
        <div className="col-lg-9 property-news-single-card pd-top-60 pb-5 border-bottom-yellow">
          <div className="row">
            {(function () {
              if (post.proprieta.struttura.includes("Monolocale")) {
                return (
                  <div className="col-md-4 col-12">
                    <div className="single-floor-list media">
                      <div className="media-left">
                        <img src={monolocale} alt={imagealt} />
                      </div>
                      <div className="media-body">
                        <h6>Monolocale</h6>
                      </div>
                    </div>
                  </div>
                )
              } else {
                return ""
              }
            })()}
            {(function () {
              if (post.proprieta.struttura.includes("Bilocale")) {
                return (
                  <div className="col-md-4 col-12">
                    <div className="single-floor-list media">
                      <div className="media-left">
                        <img src={bilocale} alt={imagealt} />
                      </div>
                      <div className="media-body">
                        <h6>Bilocale</h6>
                      </div>
                    </div>
                  </div>
                )
              } else {
                return ""
              }
            })()}
            {(function () {
              if (post.proprieta.struttura.includes("Trilocale")) {
                return (
                  <div className="col-md-4 col-12">
                    <div className="single-floor-list media">
                      <div className="media-left">
                        <img src={trilocale} alt={imagealt} />
                      </div>
                      <div className="media-body">
                        <h6>Trilocale</h6>
                      </div>
                    </div>
                  </div>
                )
              } else {
                return ""
              }
            })()}
            {(function () {
              if (post.proprieta.struttura.includes("Quadrilocale")) {
                return (
                  <div className="col-md-4 col-12">
                    <div className="single-floor-list media">
                      <div className="media-left">
                        <img src={quadrilocale} alt={imagealt} />
                      </div>
                      <div className="media-body">
                        <h6>Quadrilocale</h6>
                      </div>
                    </div>
                  </div>
                )
              } else {
                return ""
              }
            })()}
            {(function () {
              if (post.proprieta.struttura.includes("Attico")) {
                return (
                  <div className="col-md-4 col-12">
                    <div className="single-floor-list media">
                      <div className="media-left">
                        <img src={attico} alt={imagealt} />
                      </div>
                      <div className="media-body">
                        <h6>Attico</h6>
                      </div>
                    </div>
                  </div>
                )
              } else {
                return ""
              }
            })()}
            {(function () {
              if (post.proprieta.struttura.includes("Trilocale + Attico")) {
                return (
                  <div className="col-md-4 col-12">
                    <div className="single-floor-list media">
                      <div className="media-left">
                        <img src={trilocale} alt={imagealt} />
                      </div>
                      <div className="media-body">
                        <h6>Attico trilocale</h6>
                      </div>
                    </div>
                  </div>
                )
              } else {
                return ""
              }
            })()}
          </div>
        </div>
        </div>
        )
              } else {
                return ""
              }
            })()}


        <div className="container">

        <div className="row pd-top-90">
          <div className="col-lg-9">
            <div className="property-news-single-card border-bottom-yellow">
              <h4>Nelle vicinanze troverai:</h4>
              <div className="row vicinanze-row">
                <div className="col-sm-12">
                  <ul className="rld-list-style mb-3 mb-sm-0">
                    {post.proprieta.vicinanze.map((vicinanza, i) => (
                      <li key={i} className="col-md-4 col-sm-6">
                        <i className="fa fa-check" /> {vicinanza}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      

      {(function () {
      if (post.proprieta.correlati.correlati ) {
        return (

      <div className="recommended-area pd-top-90 pd-bottom-70">
        <div className="container">
          <div className="section-title">
            <h2 className="title">Ti potrebbero interessare anche…</h2>
          </div>

          <div className="row">
            {post.proprieta.correlati.correlati.map((node, i) => (
              <div key={i} className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="single-feature">
                  <Link to={"../" + node.slug}>
                    <div className="thumb">

                    {/* test */}
                    {(function () {
                      if (node.proprieta.etichetta ) {
                        return (
                          <span className="etichetta etichetta-list etichetta-list-small"
                            dangerouslySetInnerHTML={{
                              __html: `${node.proprieta.etichetta}`,
                            }}
                          ></span>
                        )
                      } else {
                        return ""
                      }
                    })()}

                    {(function () {
                      if (node.proprieta.venduta ) {
                        return (
                          <span className="etichetta etichetta-list etichetta-list-small venduta"
                            dangerouslySetInnerHTML={{
                              __html: `${node.proprieta.venduta}`,
                            }}
                          ></span>
                        )
                      } else {
                        return ""
                      }
                    })()}
                    {/* /test */}

                      <Img
                        fluid={
                          node.proprieta.immagine.localFile.childImageSharp
                            .fluid
                        }
                        alt={imagealt}
                      />
                    </div>
                  </Link>
                  <div className="details">
                    <a href="/" className="feature-logo">
                      <img src={logoProposte} alt={imagealt} />
                    </a>
                    <p className="author">{node.proprieta.citta[0].title}</p>
                    <h6 className="title readeal-top">
                      <Link to={"../" + node.slug}>{node.title}</Link>
                    </h6>

                    da Euro{" "}
                    <h6 className="price"> {node.proprieta.prezzo}</h6>

                    {/* <ul className="info-list">
                      {(function () {
                        if (node.appartamento.extra === true) {
                          return (
                            <li>
                              <i className="fa fa-car" /> Possibilità posto auto
                            </li>
                          )
                        } else {
                          return ""
                        }
                      })()}
                    </ul> */}

                    <ul className="contact-list">
                      <li>
                        <a
                          className="phone"
                          href="tel:+393391745031"
                          aria-label="icon-phone"
                        >
                          <i className="fa fa-phone" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="message"
                          href="mailto:info@sognocasaliguria.it"
                          aria-label="icon-mail"
                        >
                          <i className="fa fa-envelope" />
                        </a>
                      </li>
                      <li className="readeal-top dettagli-btn">
                        <Link className="btn btn-yellow" to={"../" + node.slug}>
                          Dettagli
                        </Link>
                      </li>
                      <li className="arrow-mobile">
                        <Link
                          className="arrow"
                          aria-label="icon-arrow"
                          to={"../" + node.slug}
                        >
                          <i className="fa fa-share" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      )
              } else {
                return ""
              }
            })()}

      <Contact bgGrayOpacity="bg-gray-opacity" />

      <Form form={post} />

    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allWpProprieta(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        slug
        proprieta {
          citta {
            ... on WpLocalita {
              id
              title
            }
          }
          correlati {
            correlati {
              ... on WpProprieta {
                title
                slug
                proprieta {
                  prezzo
                  etichetta
                  venduta
                  immagine {
                    sourceUrl
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1920, quality: 80) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  citta {
                    ... on WpLocalita {
                      id
                      title
                    }
                  }
                }
              }
            }
          }
          immagine {
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          prezzo
          slider {
            id
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          sliderContenuto {
            caratteristiche
            testoSlider
            titoloSlider
          }
          struttura
          testoDescrizione
          tipologia
          etichetta
          venduta
          titoloDescrizione
          vicinanze
        }
      }
    }
  }
`
