import React from "react"
// import ReCAPTCHA from "react-google-recaptcha";

export default function FormProprieta({ form }) {
  return (
    // <form name="Contact Form Proprieta" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" data-netlify-recaptcha='true' action="/thank-you" className="contact-form-wrap contact-form-bg container">
    <form name="Contact Form Proprietà" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/thank-you" className="contact-form-wrap contact-form-bg container">
		  <h4>Contattaci</h4>
      <input type="hidden" name="form-name" value="Contact Form Proprieta" />
		  <div className="rld-single-input form-small">
		    <input type="text" name="nome" required placeholder="Nome*" />
		  </div>
		  <div className="rld-single-input form-small">
		    <input type="text" name="cognome" required placeholder="Cognome*" />
		  </div>
		  <div className="rld-single-input form-small">
		    <input type="tel" name="telefono" required placeholder="Telefono*" />
		  </div>
		  <div className="rld-single-input form-small">
		    <input type="email" name="email" required placeholder="Email*" />
		  </div>
		  <div className="rld-single-input form-big">
		    <textarea name="messaggio" required rows={10} placeholder="Messaggio*" defaultValue={"Vorrei ricevere maggiori informazioni riguardo \"" + form.title + "\" a " + form.proprieta.citta[0].title + "."} />
		  </div>
		  <div className="rld-single-input form-big form-check">
		  	<input type="checkbox" name="termini e condizioni" required /><span>Accetto i <a className="external-link" href="/privacy-policy" target="_blank"><b>termini e le condizioni</b></a></span>
		  </div>
      {/* <ReCAPTCHA sitekey="6Le1OeoZAAAAAIKBKyPtbfgfyppCwiZycZTamfMl" /> */}
		  <div className="btn-wrap text-center">
		    {/* <button type="submit" className="btn btn-yellow">Invia</button> */}
		    <button className="btn btn-yellow">Invia</button>
		  </div>
		</form>
  )
}